import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import FieldTypeSelect from "./FieldTypeSelect/FieldTypeSelect";
import DefaultValueInput from "./DefaultValueInput/DefaultValueInput";
import FieldComment from "../FieldComment/FieldComment";
import NavigationSection from "../NavigationSection/NavigationSection";
import { SimpleAccordion } from "../../accordion/SimpleAccordion";
import DirectoryAttrs from "./DirectoryAttrs/DirectoryAttrs";
import UserGroupAttrs from "./UserGroupAttrs/UserGroupAttrs";
import DocumentAttrs from "./DocumentAttrs/DocumentAttrs";
import RequiredView from "./ValidationViews/RequiredView";
import ListRequiredView from "./ValidationViews/ListRequiredView";
import TextFormatView from "./ValidationViews/TextFormatView";
import TextLengthView from "./ValidationViews/TextLengthView";
import NumericRangeView from "./ValidationViews/NumericRangeView";
import DateRangeView from "./ValidationViews/DateRangeView";
import SchemaFieldsTable from "../SchemaFieldsTable/SchemaFieldsTable";
import { TFieldsObjectName, TSchemaObjectName } from "../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  fieldIndex: number;
  subfieldIndex?: number;
  schemaName: string;
  fieldName: string;
  subfieldName?: string;
  isImported: boolean;
  control: any;
  register: any;
  errors: any;
  setValue: any;
  getValues: any;
  //watch: any;
  //onSchemaClick: () => void;
}

export const FieldAdvancedSettings = ({
  schemaObjectName,
  fieldsObjectName,
  fieldIndex,
  subfieldIndex,
  schemaName,
  fieldName,
  subfieldName,
  isImported,
  control,
  register,
  errors,
  setValue,
  getValues,
}: IProps) => {
  const { t } = useTranslation();

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const schemaErrors = schemaObjectName ? errors?.[schemaObjectName] : errors;

  const namePrefix = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}`;

  const controlError =
    subfieldIndex === undefined
      ? schemaErrors?.[fieldsObjectName]?.[fieldIndex]
      : schemaErrors?.[fieldsObjectName]?.[fieldIndex]?.subFields?.[
          subfieldIndex
        ];

  const fieldsWatches = useWatch({
    control,
    name: [`${namePrefix}.fieldType`],
  });

  return (
    <>
      <NavigationSection
        schemaName={schemaName}
        fieldName={fieldName}
        fieldIndex={fieldIndex}
        subfieldName={subfieldName}
        subfieldIndex={subfieldIndex}
      />

      <Box>
        <Controller
          name={`${namePrefix}.name`}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                value={value}
                label={t("fieldName")}
                variant="outlined"
                size="small"
                sx={{
                  paddingRight: "1em",
                }}
                {...register(`${namePrefix}.name`, {
                  required: {
                    value: true,
                    message: t("inputRequired").replace("%1", t("fieldName")),
                  },
                  minLength: {
                    value: 2,
                    message: t("minValidation").replace("%1", "2"),
                  },
                  maxLength: {
                    value: 256,
                    message: t("maxValidation").replace("%1", "256"),
                  },
                  pattern: {
                    value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                    message: t("patternValidation"),
                  },
                })}
                onChange={onChange}
                helperText={controlError?.name?.message}
                error={controlError?.name !== undefined}
              />
            );
          }}
        />
        <FieldTypeSelect
          variant={"FIELD"}
          name={`${namePrefix}.fieldType`}
          fieldNamePrefix={namePrefix}
          isImported={getValues("isImported")}
          control={control}
          register={register}
          setValue={setValue}
          helperText={controlError?.fieldType?.message}
          error={controlError?.fieldType !== undefined}
          defaultInputName={`${namePrefix}.defaultValue`}
          referenceConfigurationInputName={`${namePrefix}.referenceConfiguration`}
        />

        <Controller
          name={`${namePrefix}.isMultiValue`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Checkbox onChange={onChange} checked={value} />}
              label={t("fieldMulti")}
              sx={{ paddingLeft: "1em" }}
            />
          )}
        />
        <DefaultValueInput
          name={`${namePrefix}.defaultValue`}
          control={control}
          register={register}
          getValues={getValues}
          fieldTypeInputName={`${namePrefix}.fieldType`}
          isImported={isImported}
        />
        <FieldComment field={`${namePrefix}`} control={control} />
      </Box>

      {/*Набор установок зависит от типа поля*/}
      {fieldsWatches[0] === "COMPLEX" && (
        <SimpleAccordion
          title={t("subfields")}
          elevation={2}
          sx={{ marginTop: "1em" }}
        >
          <SchemaFieldsTable
            schemaObjectName={schemaObjectName}
            fieldsObjectName={fieldsObjectName}
            isImported={isImported}
            fieldName={fieldName}
            fieldIndex={fieldIndex}
            control={control}
            register={register}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
          />
        </SimpleAccordion>
      )}
      {fieldsWatches[0] === "DIRECTORY" && (
        <SimpleAccordion
          title={t("directoryFieldType")}
          elevation={2}
          sx={{ marginTop: "1em" }}
        >
          <DirectoryAttrs
            schemaObjectName={schemaObjectName}
            fieldsObjectName={fieldsObjectName}
            schemaName={schemaName}
            fieldName={fieldName}
            fieldIndex={fieldIndex}
            subfieldName={subfieldName}
            subfieldIndex={subfieldIndex}
            register={register}
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
          />
        </SimpleAccordion>
      )}
      {fieldsWatches[0] === "USER_GROUP" && (
        <SimpleAccordion
          title={t("userGroupFieldType")}
          elevation={2}
          sx={{ marginTop: "1em" }}
        >
          <UserGroupAttrs
            schemaObjectName={schemaObjectName}
            fieldsObjectName={fieldsObjectName}
            schemaName={schemaName}
            fieldName={fieldName}
            fieldIndex={fieldIndex}
            subfieldName={subfieldName}
            subfieldIndex={subfieldIndex}
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
          />
        </SimpleAccordion>
      )}
      {fieldsWatches[0] === "DOCUMENT" && (
        <SimpleAccordion
          title={t("documentFieldType")}
          elevation={2}
          sx={{ marginTop: "1em" }}
        >
          <DocumentAttrs
            schemaObjectName={schemaObjectName}
            fieldsObjectName={fieldsObjectName}
            schemaName={schemaName}
            fieldName={fieldName}
            fieldIndex={fieldIndex}
            subfieldName={subfieldName}
            subfieldIndex={subfieldIndex}
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
          />
        </SimpleAccordion>
      )}
      <SimpleAccordion
        title={t("validation")}
        elevation={2}
        sx={{ marginTop: "1em" }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RequiredView
              schemaObjectName={schemaObjectName}
              fieldsObjectName={fieldsObjectName}
              schemaName={schemaName}
              fieldName={fieldName}
              fieldIndex={fieldIndex}
              subfieldName={subfieldName}
              subfieldIndex={subfieldIndex}
              control={control}
            />
            <ListRequiredView
              schemaObjectName={schemaObjectName}
              fieldsObjectName={fieldsObjectName}
              schemaName={schemaName}
              fieldName={schemaName}
              fieldIndex={fieldIndex}
              subfieldName={subfieldName}
              subfieldIndex={subfieldIndex}
              control={control}
            />
            {[
              "STRING",
              "INTEGER",
              "FLOATING_POINT",
              "DIRECTORY",
              "USER_GROUP",
              "DOCUMENT",
            ].includes(fieldsWatches[0]) && (
              <TextFormatView
                schemaObjectName={schemaObjectName}
                fieldsObjectName={fieldsObjectName}
                schemaName={schemaName}
                fieldName={fieldName}
                fieldIndex={fieldIndex}
                subfieldName={subfieldName}
                subfieldIndex={subfieldIndex}
                control={control}
                getValues={getValues}
                setValue={setValue}
              />
            )}
            {["STRING", "DIRECTORY", "USER_GROUP", "DOCUMENT"].includes(
              fieldsWatches[0]
            ) && (
              <TextLengthView
                schemaObjectName={schemaObjectName}
                fieldsObjectName={fieldsObjectName}
                schemaName={schemaName}
                fieldName={fieldName}
                fieldIndex={fieldIndex}
                subfieldName={subfieldName}
                subfieldIndex={subfieldIndex}
                control={control}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
            )}
            {["INTEGER", "FLOATING_POINT"].includes(fieldsWatches[0]) && (
              <NumericRangeView
                schemaObjectName={schemaObjectName}
                fieldsObjectName={fieldsObjectName}
                schemaName={schemaName}
                fieldName={fieldName}
                fieldIndex={fieldIndex}
                subfieldName={subfieldName}
                subfieldIndex={subfieldIndex}
                control={control}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
            )}
            {["DATE"].includes(fieldsWatches[0]) && (
              <DateRangeView
                schemaObjectName={schemaObjectName}
                fieldsObjectName={fieldsObjectName}
                schemaName={schemaName}
                fieldName={fieldName}
                fieldIndex={fieldIndex}
                subfieldName={subfieldName}
                subfieldIndex={subfieldIndex}
                control={control}
                register={register}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
            )}
          </Box>
        </Box>
      </SimpleAccordion>
    </>
  );
};
