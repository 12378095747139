import { useSearchParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

interface IProps {
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
}
const NavigationSection = ({
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFieldButtonClick = (event: any) => {
    searchParams.delete("subfield");
    setSearchParams(searchParams);
  };

  const handleSchemaButtonClick = (event: any) => {
    searchParams.delete("field");
    searchParams.delete("subfield");
    setSearchParams(searchParams);
  };

  const buttonStyle = {
    marginRight: "0.5em",
    color: "#1976d2",
    border: "1px solid rgba(25, 118, 210, 0.5)",
    fontSize: "0.8rem",
    textTransform: "none",
  };

  const divStyle = {
    height: "auto",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "rgb(25, 118, 210)",
    marginLeft: "0.25em",
    paddingInline: "1em",
  };

  return (
    <Box sx={{ display: "flex", mb: "20px" }}>
      <Button sx={buttonStyle} onClick={handleSchemaButtonClick}>
        {schemaName}
      </Button>
      <ArrowRightAltIcon fontSize="large" />
      {subfieldName && subfieldIndex !== undefined ? (
        <Button sx={buttonStyle} onClick={handleFieldButtonClick}>
          {fieldName}
        </Button>
      ) : (
        <div style={divStyle}>{fieldName}</div>
      )}
      {subfieldName && subfieldIndex !== undefined && (
        <>
          <ArrowRightAltIcon fontSize="large" />
          <div style={divStyle}>{subfieldName}</div>
        </>
      )}
    </Box>
  );
};

export default NavigationSection;
