import { Box, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SchemaFieldsTable from "./SchemaFieldsTable/SchemaFieldsTable";
import { useSearchParams } from "react-router-dom";
import { FieldAdvancedSettings } from "./FieldAdvancedSettings/FieldAdvancedSettings";
import { useEffect, useState } from "react";
import { SubfieldAdvancedSettings } from "./FieldAdvancedSettings/SubfieldAdvancedSettings";
import {
  TFieldsObjectName,
  TSchemaNameFieldName,
  TSchemaObjectName,
} from "./types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  schemaNameFieldName?: TSchemaNameFieldName;
  fieldsObjectName: TFieldsObjectName;
  isImported: boolean;
  control: any;
  register: any;
  errors: any;
  getValues: any;
  setValue: any;
}

export const SchemaForm = ({
  schemaObjectName,
  schemaNameFieldName = "featureId",
  fieldsObjectName,
  isImported,
  control,
  register,
  errors,
  getValues,
  setValue,
}: IProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const fieldSearchParam = searchParams.get("field") || null;
  const subfieldSearchParam = searchParams.get("subfield") || null;
  const [selectedFieldName, setSelectedFieldName] = useState<
    string | undefined
  >(undefined);
  const [selectedSubfieldName, setSelectedSubfieldName] = useState<
    string | undefined
  >(undefined);

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const schemaName = `${schemaNamePrefix}${schemaNameFieldName}`;
  const schemaErrors = schemaObjectName ? errors?.[schemaObjectName] : errors;

  // EFFECTS
  useEffect(() => {
    if (fieldSearchParam !== null) {
      setSelectedFieldName(
        getValues(
          `${schemaNamePrefix}${fieldsObjectName}.${Number(
            fieldSearchParam
          )}.name`
        )
      );
    } else {
      setSelectedFieldName(undefined);
    }
  }, [fieldSearchParam]);

  useEffect(() => {
    if (fieldSearchParam !== null && subfieldSearchParam !== null) {
      setSelectedSubfieldName(
        getValues(
          `${schemaNamePrefix}${fieldsObjectName}.${Number(
            fieldSearchParam
          )}.subFields.${Number(subfieldSearchParam)}.name`
        )
      );
    } else {
      setSelectedSubfieldName(undefined);
    }
  }, [fieldSearchParam, subfieldSearchParam]);

  useEffect(() => {
    console.log(selectedFieldName);
  }, [selectedFieldName]);

  if (fieldSearchParam === null) {
    return (
      <Stack spacing={3}>
        <Box display="flex" marginBottom="10px">
          <Box display="flex" alignItems="center" sx={{ paddingRight: "1em" }}>
            <Controller
              name={schemaName}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled
                  variant="outlined"
                  size="small"
                  label={t("schemaName")}
                />
              )}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Controller
              name={`${schemaNamePrefix}prefix`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  size="small"
                  label={t("schemaPrefix")}
                  {...register(`${schemaNamePrefix}prefix`, {
                    required: {
                      value: true,
                      message: t("inputRequired").replace(
                        "%1",
                        t("schemaPrefix")
                      ),
                    },
                    minLength: {
                      value: 2,
                      message: t("minValidation").replace("%1", "2"),
                    },
                    maxLength: {
                      value: 256,
                      message: t("maxValidation").replace("%1", "256"),
                    },
                    pattern: {
                      value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                      message: t("patternValidation"),
                    },
                  })}
                  helperText={schemaErrors?.prefix?.message}
                  error={schemaErrors?.prefix !== undefined}
                />
              )}
            />
          </Box>
          {isImported && (
            <Box display="flex" alignItems="center" sx={{ paddingLeft: "1em" }}>
              <Typography textAlign="center">
                {t("importedFromXsdFile")}
              </Typography>
            </Box>
          )}
        </Box>
        <SchemaFieldsTable
          schemaObjectName={schemaObjectName}
          fieldsObjectName={fieldsObjectName}
          isImported={isImported}
          control={control}
          register={register}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
        />
      </Stack>
    );
  }

  if (subfieldSearchParam === null && selectedFieldName) {
    return (
      <FieldAdvancedSettings
        schemaObjectName={schemaObjectName}
        fieldsObjectName={fieldsObjectName}
        fieldIndex={Number(fieldSearchParam)}
        schemaName={getValues(schemaName)}
        fieldName={selectedFieldName}
        subfieldName={undefined}
        subfieldIndex={undefined}
        isImported={isImported}
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
      />
    );
  }

  if (subfieldSearchParam && selectedFieldName && selectedSubfieldName) {
    return (
      <SubfieldAdvancedSettings
        schemaObjectName={schemaObjectName}
        fieldsObjectName={fieldsObjectName}
        fieldIndex={Number(fieldSearchParam)}
        subfieldIndex={Number(subfieldSearchParam)}
        schemaName={getValues(schemaName)}
        fieldName={selectedFieldName}
        subfieldName={selectedSubfieldName}
        isImported={isImported}
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
      />
    );
  }

  return <></>;
};
