import { Checkbox, FormControl, Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LabelTextFormat, TextFormatCheckbox } from "./ValidationLabels";
import { ControlledSelect } from "../../../select/ControlledSelect";
import { PatternLists } from "../../../../../staticValues/Constants";
import { constraintsParamNames } from "./constants";
import { TFieldsObjectName, TSchemaObjectName } from "../../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
  control: any;
  getValues: any;
  setValue: any;
}

export default function TextFormatView({
  schemaObjectName,
  fieldsObjectName,
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
  control,
  getValues,
  setValue,
}: IProps) {
  const { t } = useTranslation();

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const namePrefix = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}`;
  const paramName = "textFormatConstraint";
  const index = constraintsParamNames.findIndex(
    (element: string) => element === paramName
  );
  const controlName = `${namePrefix}.constraints.${index}.${paramName}.pattern`;

  const pattern: string = getValues(controlName);
  const [isShowPattern, setShowPattern] = useState(pattern ? true : false);

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ marginTop: "1em", marginLeft: "1em" }}
      >
        <Checkbox
          onChange={() => setShowPattern(!isShowPattern)}
          checked={isShowPattern}
        />
        <FormControl sx={{ cursor: "default", width: "100%" }}>
          <TextFormatCheckbox />
        </FormControl>
      </Stack>
      {isShowPattern && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "3em",
          }}
        >
          <div
            style={{
              marginTop: "1em",
              marginBottom: "0.5em",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Controller
              name={controlName}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  label={t("validPattern")}
                  value={value === null ? "" : value}
                  onChange={onChange}
                  sx={{
                    width: "30%",
                  }}
                />
              )}
            />
            <ControlledSelect
              valueList={PatternLists}
              selectedValue={pattern === null ? "unknown" : pattern}
              size="small"
              label={t("validPattern")}
              sx={{
                width: "200px",
                marginLeft: "1em",
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(
                  controlName,
                  e.target.value === "unknown" ? null : e.target.value,
                  { shouldDirty: true }
                )
              }
            />
          </div>
          <LabelTextFormat
            schema={schemaName}
            field={subfieldName ? subfieldName : fieldName}
          />
        </div>
      )}
    </Fragment>
  );
}
