import { Box, Checkbox, FormControl, Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { constraintsParamNames } from "./constants";
import { LabelTextLength, TextLengthCheckbox } from "./ValidationLabels";
import { TFieldsObjectName, TSchemaObjectName } from "../../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
  control: any;
  register: any;
  errors: any;
  getValues: any;
  setValue: any;
}

export default function TextLengthView({
  schemaObjectName,
  fieldsObjectName,
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
  control,
  register,
  errors,
  getValues,
  setValue,
}: IProps) {
  const { t } = useTranslation();

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const schemaErrors = schemaObjectName ? errors?.[schemaObjectName] : errors;

  const namePrefix = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}`;
  const paramName = "textLengthConstraint";
  const index = constraintsParamNames.findIndex(
    (element: string) => element === paramName
  );
  const controlMinName = `${namePrefix}.constraints.${index}.${paramName}.minimum`;
  const controlMaxName = `${namePrefix}.constraints.${index}.${paramName}.maximum`;
  const controlMinError =
    schemaErrors?.[fieldsObjectName]?.[fieldIndex]?.constraints?.[index]
      ?.textLengthConstraint?.minimum;
  const controlMaxError =
    schemaErrors?.[fieldsObjectName]?.[fieldIndex]?.constraints?.[index]
      ?.textLengthConstraint?.maximum;
  const textLengthConstraint: Array<any | number> = getValues([
    controlMinName,
    controlMaxName,
  ]);
  const [isWatchTextLen, setWatchTextLen] = useState(
    textLengthConstraint[0] || textLengthConstraint[1]
  );

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ marginTop: "1em", marginLeft: "1em" }}
      >
        <Checkbox
          onChange={() => setWatchTextLen(!isWatchTextLen)}
          checked={isWatchTextLen}
        />
        <FormControl sx={{ cursor: "default", width: "100%" }}>
          <TextLengthCheckbox />
        </FormControl>
      </Stack>
      {isWatchTextLen && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              marginLeft: "3em",
              marginTop: "1em",
            }}
          >
            <Controller
              name={controlMinName}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  size="small"
                  type="number"
                  label={t("validMinimum")}
                  sx={{
                    marginRight: "1em",
                  }}
                  {...register(controlMinName, {
                    min: {
                      value: 0,
                      message: t("minValueValidation").replace("%1", "0"),
                    },
                    max: {
                      value: 999999999,
                      message: t("maxValueValidation").replace(
                        "%1",
                        "999999999"
                      ),
                    },
                    pattern: {
                      value: /^\d+$/,
                      message: t("onlyIntegerNumber"),
                    },
                  })}
                  helperText={controlMinError?.message}
                  error={controlMinError !== undefined}
                />
              )}
            />
            <Controller
              name={controlMaxName}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  {...register(controlMaxName, {
                    min: {
                      value: 0,
                      message: t("minValidation").replace("%1", "0"),
                    },
                    max: {
                      value: 999999999,
                      message: t("maxValidation").replace("%1", "999999999"),
                    },
                    pattern: {
                      value: /^\d+$/,
                      message: t("onlyIntegerNumber"),
                    },
                  })}
                  variant="outlined"
                  size="small"
                  type="number"
                  label={<Trans i18nKey={"validMaximum"} />}
                  helperText={controlMaxError?.message}
                  error={controlMaxError !== undefined}
                />
              )}
            />
          </Box>
          <LabelTextLength
            schema={schemaName}
            field={subfieldName ? subfieldName : fieldName}
          />
        </div>
      )}
    </Fragment>
  );
}
