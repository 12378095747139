import { Box, Button, Paper } from "@mui/material";
import {
  SchemaWithFieldsPropsGet,
  SchemaWithFieldsPropsUpdate,
} from "../../../../../types/content/schemas/SchemaWithFieldsProps";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { Page } from "../../../../common/page/Page";
import { PageHeader } from "../../../../common/page/PageHeader";
import { PageContent } from "../../../../common/page/PageContent";
import { useSearchParams } from "react-router-dom";
import useUpdate from "../../../../../serverInteraction/hooks/entity/useUpdate";
import { createContext, useContext, useEffect } from "react";
import SchemaTitle from "./SchemaTitle";
import { SchemaForm } from "../../../../common/schemaForm/SchemaForm";
import { useNavigationPrompt } from "../../../../../hooks/useNavigationPrompt/useNavigationPrompt";
import ConfirmDiscardDialog from "../../../../common/dialog/ConfirmDiscardDialog";
import { formatFieldConstrants } from "../../../../common/schemaForm/FieldAdvancedSettings/ValidationViews/utils";

interface IProps {
  schema: SchemaWithFieldsPropsGet;
  refetch: any;
}

const SchemaView = ({ schema, refetch }: IProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { update } = useUpdate<
    SchemaWithFieldsPropsUpdate,
    SchemaWithFieldsPropsGet
  >("schemas");

  const FormContext = createContext<Partial<SchemaWithFieldsPropsUpdate>>({});

  const formMethods = useForm<SchemaWithFieldsPropsUpdate>({
    defaultValues: schema,
  });

  const {
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    formState: { errors, isDirty },
    reset,
    watch,
  } = formMethods;

  //EVENTS
  const onSubmit = async (data: SchemaWithFieldsPropsUpdate) => {
    let dataForUpdate: SchemaWithFieldsPropsUpdate = data;
    //отформатируем fields перед отправкой
    //constraints: обнулим все неподходящее, отправляем только ненулевые
    dataForUpdate.fields.forEach((field) => {
      field.constraints = formatFieldConstrants(
        field.constraints,
        field.fieldType,
        field.isMultiValue
      );
      field.subFields?.forEach((subfield) => {
        subfield.constraints = formatFieldConstrants(
          subfield.constraints,
          subfield.fieldType,
          subfield.isMultiValue
        );
      });
    });

    try {
      await update("id", dataForUpdate);
      setSearchParams();
      refetch();
    } catch (error: any) {
      console.log(error);
    }
  };

  const onNavigationSave = () => {
    onSubmit(getValues());
  };

  useNavigationPrompt({ isDirty, onSave: onNavigationSave });

  const handleDiscardClick = () => {
    reset();
  };

  return (
    <Page>
      <PageHeader title={`${t("schemaName")} ${schema.featureId}`}></PageHeader>
      <PageContent subtitle={<SchemaTitle />}>
        <FormProvider {...formMethods}>
          <Paper elevation={2} square sx={{ p: 2 }}>
            <SchemaForm
              fieldsObjectName="fields"
              isImported={schema.isImported}
              control={control}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
            />
          </Paper>
          <Box
            sx={{
              width: "100%",
              justifyContent: "right",
              display: "flex",
              paddingTop: "1em",
            }}
          >
            <ConfirmDiscardDialog
              buttonTitle={t("button_discard")}
              isNeedConfirm={isDirty}
              onConfirm={handleDiscardClick}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              sx={{ marginLeft: "1em" }}
            >
              {t("save")}
            </Button>
          </Box>
        </FormProvider>
      </PageContent>
    </Page>
  );
};

export default SchemaView;
