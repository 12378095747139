import { Box, FormControl, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ControlledSelect } from "../../../select/ControlledSelect";
import { ListUserGroupContains } from "../../../../../staticValues/Constants";
import { LabelUserGroupContains } from "../ValidationViews/ValidationLabels";
import { TFieldsObjectName, TSchemaObjectName } from "../../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
  register: any;
  control: any;
  setValue: any;
  errors: any;
}

const UserGroupAttrs = ({
  schemaObjectName,
  fieldsObjectName,
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
  register,
  control,
  errors,
  setValue,
}: IProps) => {
  const { t } = useTranslation();

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const schemaErrors = schemaObjectName ? errors?.[schemaObjectName] : errors;

  const controlName = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.referenceConfiguration.firstParameterValue`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}.referenceConfiguration.firstParameterValue`;

  const controlError =
    subfieldIndex === undefined
      ? schemaErrors?.[fieldsObjectName]?.[fieldIndex].referenceConfiguration
          ?.firstParameterValue
      : schemaErrors?.[fieldsObjectName]?.[fieldIndex]?.subFields?.[
          subfieldIndex
        ].referenceConfiguration?.firstParameterValue;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(controlName, event.target.value, { shouldDirty: true });
  };

  return (
    <Box>
      <Stack display="flex" flexDirection="row" marginTop="1em">
        <Controller
          name={controlName}
          control={control}
          render={({ field: { value } }) => (
            <FormControl variant="outlined">
              <ControlledSelect
                value={value}
                valueList={ListUserGroupContains}
                size="small"
                label={t("userGroupFieldContains")}
                sx={{
                  width: 250,
                }}
                {...register(controlName, {
                  required: {
                    value: true,
                    message: t("inputRequired").replace(
                      "%1",
                      t("userGroupFieldContains")
                    ),
                  },
                })}
                isTranslate={true}
                helperText={controlError?.message}
                error={controlError !== undefined}
                onChange={handleChange}
              />
            </FormControl>
          )}
        />
      </Stack>
      <LabelUserGroupContains
        schema={schemaName}
        field={subfieldName ? subfieldName : fieldName}
      />
    </Box>
  );
};

export default UserGroupAttrs;
