import { Checkbox, FormControl, Stack } from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { constraintsParamNames } from "./constants";
import { InfoListRequired, LabelRequired } from "./ValidationLabels";
import { TFieldsObjectName, TSchemaObjectName } from "../../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
  control: any;
}

export default function ListRequiredView({
  schemaObjectName,
  fieldsObjectName,
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
  control,
}: IProps) {
  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const namePrefix = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}`;

  const paramName = "listElementsMandatoryConstraint";
  const index = constraintsParamNames.findIndex(
    (element: string) => element === paramName
  );

  const controlName = `${namePrefix}.constraints.${index}.${paramName}.value`;

  const fieldsWatches = useWatch({
    control,
    name: [`${namePrefix}.isMultiValue`, controlName],
  });

  if (fieldsWatches[0]) {
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginTop: "1em", marginLeft: "1em" }}
        >
          <Controller
            name={controlName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox onChange={onChange} checked={value} />
            )}
          />
          <FormControl sx={{ cursor: "default", width: "100%" }}>
            <InfoListRequired />
          </FormControl>
        </Stack>
        {fieldsWatches[1] && (
          <LabelRequired
            schema={schemaName}
            field={subfieldName ? subfieldName : fieldName}
          />
        )}
      </>
    );
  }

  return <></>;
}
