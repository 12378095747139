import { Checkbox, FormControl, Stack } from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { constraintsParamNames } from "./constants";
import { Inforequired, LabelRequired } from "./ValidationLabels";
import { TFieldsObjectName, TSchemaObjectName } from "../../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
  control: any;
}

export default function RequiredView({
  schemaObjectName,
  fieldsObjectName,
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
  control,
}: IProps) {
  const paramName = "mandatoryConstraint";
  const index = constraintsParamNames.findIndex(
    (element: string) => element === paramName
  );

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";

  const controlName = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.constraints.${index}.${paramName}.value`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}.constraints.${index}.${paramName}.value`;

  const watchParams = useWatch({
    control,
    name: [controlName],
  });

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ marginLeft: "1em" }}>
        <Controller
          name={controlName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox onChange={onChange} checked={value} />
          )}
        />
        <FormControl sx={{ cursor: "default", width: "100%" }}>
          <Inforequired />
        </FormControl>
      </Stack>
      {watchParams[0] && (
        <LabelRequired
          schema={schemaName}
          field={subfieldName ? subfieldName : fieldName}
        />
      )}
    </>
  );
}
