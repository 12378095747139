import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Fragment, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { constraintsParamNames } from "./constants";
import { LabelNumericRange, NumericRangeCheckbox } from "./ValidationLabels";
import { TFieldsObjectName, TSchemaObjectName } from "../../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
  control: any;
  register: any;
  errors: any;
  getValues: any;
  setValue: any;
}

export default function NumericRangeView({
  schemaObjectName,
  fieldsObjectName,
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
  control,
  register,
  errors,
  getValues,
  setValue,
}: IProps) {
  const { t } = useTranslation();

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const schemaErrors = schemaObjectName ? errors?.[schemaObjectName] : errors;
  const namePrefix = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}`;
  const paramName = "numericRangeConstraint";
  const index = constraintsParamNames.findIndex(
    (element: string) => element === paramName
  );
  const controlMinName = `${namePrefix}.constraints.${index}.${paramName}.minimum`;
  const controlMinInclusiveName = `${namePrefix}.constraints.${index}.${paramName}.minimumInclusive`;
  const controlMaxName = `${namePrefix}.constraints.${index}.${paramName}.maximum`;
  const controlMaxInclusiveName = `${namePrefix}.constraints.${index}.${paramName}.maximumInclusive`;
  const controlMinError =
    schemaErrors?.[fieldsObjectName]?.[fieldIndex]?.constraints?.[index]
      ?.numericRangeConstraint?.minimum;
  const controlMaxError =
    schemaErrors?.[fieldsObjectName]?.[fieldIndex]?.constraints?.[index]
      ?.numericRangeConstraint?.maximum;

  const numericRangeConstraint: Array<any | number> = getValues([
    controlMinName,
    controlMaxName,
  ]);
  const [isWatchNumericRange, setWatchNumericRange] = useState(
    numericRangeConstraint[0] || numericRangeConstraint[1]
  );
  const fieldsWatches = useWatch({
    control,
    name: [controlMinName, controlMaxName],
  });

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ marginTop: "1em", marginLeft: "1em" }}
      >
        <Checkbox
          onChange={() => setWatchNumericRange(!isWatchNumericRange)}
          checked={isWatchNumericRange}
        />
        <FormControl sx={{ cursor: "default", width: "100%" }}>
          <NumericRangeCheckbox />
        </FormControl>
      </Stack>
      {isWatchNumericRange && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              marginLeft: "3em",
              marginTop: "1em",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                marginBottom: "1em",
              }}
            >
              <Controller
                name={controlMinName}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    size="small"
                    type="number"
                    label={t("validMinimum")}
                    {...register(controlMinName, {
                      min: {
                        value: 0,
                        message: t("minValueValidation").replace("%1", "0"),
                      },
                      max: {
                        value: 999999999,
                        message: t("maxValueValidation").replace(
                          "%1",
                          "999999999"
                        ),
                      },
                      pattern: {
                        value: /^\d+$/,
                        message: t("onlyIntegerNumber"),
                      },
                    })}
                    helperText={controlMinError?.message}
                    error={controlMinError !== undefined}
                  />
                )}
              />
              <Controller
                name={controlMinInclusiveName}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsWatches[0] === ""}
                        onChange={onChange}
                        checked={value}
                      />
                    }
                    label={t("includes")}
                  />
                )}
              />
            </Box>
            <Box style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <Controller
                name={controlMaxName}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...register(controlMaxName, {
                      min: {
                        value: 0,
                        message: t("minValidation").replace("%1", "0"),
                      },
                      max: {
                        value: 999999999,
                        message: t("maxValidation").replace("%1", "999999999"),
                      },
                      pattern: {
                        value: /^\d+$/,
                        message: t("onlyIntegerNumber"),
                      },
                    })}
                    variant="outlined"
                    size="small"
                    type="number"
                    label={t("validMaximum")}
                    helperText={controlMaxError?.message}
                    error={controlMaxError !== undefined}
                  />
                )}
              />
              <Controller
                name={controlMaxInclusiveName}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsWatches[1] === ""}
                        onChange={onChange}
                        checked={value}
                      />
                    }
                    label={t("includes")}
                  />
                )}
              />
            </Box>
          </Box>
          <LabelNumericRange
            schema={schemaName}
            field={subfieldName ? subfieldName : fieldName}
          />
        </div>
      )}
    </Fragment>
  );
}
