import { Box, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DirectoryNameDialog from "./DirectoryNameDialog";
import { LabelDirectoryField } from "../ValidationViews/ValidationLabels";
import { TFieldsObjectName, TSchemaObjectName } from "../../types";

interface IProps {
  schemaObjectName?: TSchemaObjectName;
  fieldsObjectName: TFieldsObjectName;
  schemaName: string;
  fieldName: string;
  fieldIndex: number;
  subfieldName?: string;
  subfieldIndex?: number;
  register: any;
  control: any;
  setValue: any;
  getValues: any;
  errors: any;
}

const DirectoryAttrs = ({
  schemaObjectName,
  fieldsObjectName,
  schemaName,
  fieldName,
  fieldIndex,
  subfieldName,
  subfieldIndex,
  register,
  control,
  errors,
  setValue,
  getValues,
}: IProps) => {
  const { t } = useTranslation();

  const schemaNamePrefix = schemaObjectName ? `${schemaObjectName}.` : "";
  const schemaErrors = schemaObjectName ? errors?.[schemaObjectName] : errors;

  const controlName = !subfieldName
    ? `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.referenceConfiguration.firstParameterValue`
    : `${schemaNamePrefix}${fieldsObjectName}.${fieldIndex}.subFields.${subfieldIndex}.referenceConfiguration.firstParameterValue`;

  const controlError =
    subfieldIndex === undefined
      ? schemaErrors?.[fieldsObjectName]?.[fieldIndex].referenceConfiguration
          ?.firstParameterValue
      : schemaErrors?.[fieldsObjectName]?.[fieldIndex]?.subFields?.[
          subfieldIndex
        ].referenceConfiguration?.firstParameterValue;

  return (
    <Box>
      <Stack display="flex" flexDirection="row" gap="1em" marginTop="1em">
        <Controller
          name={controlName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              label={t("directoryFieldName")}
              value={value === null ? "" : value}
              onChange={onChange}
              {...register(controlName, {
                minLength: {
                  value: 3,
                  message: t("minValidation").replace("%1", "3"),
                },
                pattern: {
                  value: /^[A-Za-z]{1}[A-Za-z\-\_0-9]+$/,
                  message: t("patternValidation"),
                },
                required: {
                  value: true,
                  message: t("inputRequired").replace(
                    "%1",
                    t("directoryFieldName")
                  ),
                },
              })}
              size="small"
              helperText={controlError?.message}
              error={controlError !== undefined}
            />
          )}
        />
        <Typography marginTop="10px">{t("or")}</Typography>

        <DirectoryNameDialog
          initialValue={getValues(controlName)}
          buttonTitle={t("dirSelectVocabulary")}
          onConfirm={(value: string) =>
            setValue(controlName, value, { shouldDirty: true })
          }
        />
      </Stack>
      <LabelDirectoryField
        schema={schemaName}
        field={subfieldName ? subfieldName : fieldName}
      />
    </Box>
  );
};

export default DirectoryAttrs;
