import { useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { SimpleAccordion } from "../../../../../common/accordion/SimpleAccordion";
import { TransferList } from "../../../../../common/transfetList/TransferList";
import { DefaultSchemasForDocument } from "../../../../../../enum/DefaultSchemasForDocument";
import { SchemaWithFieldsPropsUpdate } from "../../../../../../types/content/schemas/SchemaWithFieldsProps";
import { SchemaForm } from "../../../../../common/schemaForm/SchemaForm";

interface IProps {
  extendType: string;
  inheritedSchemas: string;
  schemasBroughtByFacets: string;
  schemasList: string[];
  initialCustomSchema: SchemaWithFieldsPropsUpdate;
  control: any;
  register: any;
  errors: any;
  getValues: any;
  setValue: any;
  watch: any;
}

const SchemaTab = ({
  extendType,
  schemasList,
  inheritedSchemas,
  schemasBroughtByFacets,
  initialCustomSchema,
  control,
  register,
  errors,
  getValues,
  setValue,
  watch,
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const loadedSchemas = getValues("schemas");
  const schemasFullList: string[] =
    DefaultSchemasForDocument.concat(schemasList).sort();

  const updateSchemas = (newList: any) => {
    setValue("schemas", newList);
  };

  return (
    <>
      <Box>
        <Stack direction="row" gap="10px">
          <Typography>This document inherits from:</Typography>
          <Typography fontWeight="bold">{extendType}</Typography>
        </Stack>
        <Stack direction="row" gap="10px">
          <Typography>Inherited schemas:</Typography>
          <Typography fontWeight="bold">{inheritedSchemas}</Typography>
        </Stack>
        <Stack direction="row" gap="10px">
          <Typography>Schemas brought by facets:</Typography>
          <Typography fontWeight="bold">{schemasBroughtByFacets}</Typography>
        </Stack>
      </Box>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <SimpleAccordion
          title="Add extra schemas to this document type"
          sx={{ mt: 3 }}
          defaultExpanded={true}
        >
          <TransferList
            entityList={schemasFullList}
            updateParent={updateSchemas}
            loadEntities={loadedSchemas}
          />
        </SimpleAccordion>
      </div>
      <SchemaForm
        schemaObjectName={"customSchema"}
        fieldsObjectName="fields"
        isImported={false}
        control={control}
        register={register}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
      />
    </>
  );
};

export default SchemaTab;
